import { Injectable } from '@angular/core';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private darkThemeClass = 'dark';
  private lightThemeClass = 'light';
  private themeKey = 'app-theme';

  constructor(private commuServ: CommunicationService) {
    const savedTheme = localStorage.getItem(this.themeKey);
    if (savedTheme) {
      this.setTheme(savedTheme);
    } else {
      this.setTheme(this.lightThemeClass);
    }
  }

  setTheme(theme: string) {
    const body = document.body;
    body.setAttribute('data-bs-theme', theme);
    localStorage.setItem(this.themeKey, theme);
  }

  toggleTheme() {
    const body = document.body;
    const currentTheme = body.getAttribute('data-bs-theme');
    const newTheme = currentTheme === this.darkThemeClass ? this.lightThemeClass : this.darkThemeClass;
    this.setTheme(newTheme);
    this.commuServ.sendToggleThemeEvent();
  }

  isDarkTheme(): boolean {
    const body = document.body;
    return body.getAttribute('data-bs-theme') === this.darkThemeClass;
  }
}
